import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loading from '../../components/preloader/Preloader';

import {
    login,
    logout,
    getEmailsQueue,
    startEmailsQueue,
    stopEmailsQueue,
    sendTestEmail,
    downloadEmailsQueue,
    restoreEmailsQueue
} from '../../actions/adminActions';

import './admin.scss';

class PageAdmin extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        isEmailsQueueLoading: PropTypes.bool,
        isActionLoading: PropTypes.bool,
        accessAllowed: PropTypes.bool,
        emailsQueue: PropTypes.object,
        login: PropTypes.func,
        logout: PropTypes.func,
        getEmailsQueue: PropTypes.func,
        startEmailsQueue: PropTypes.func,
        stopEmailsQueue: PropTypes.func,
        sendTestEmail: PropTypes.func,
        downloadEmailsQueue: PropTypes.func,
        restoreEmailsQueue: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            showSendEmailForm: false,
            showUploadQueueDataForm: false,
            email: '',
            file: null
        };
    }

    componentDidMount() {
        const { accessAllowed } = this.props;
        if (accessAllowed) {
            this.props.getEmailsQueue();
        }
    }

    componentDidUpdate(prevProps) {
        const { accessAllowed } = this.props;
        const { accessAllowed: prevAccessAllowed } = prevProps;

        if (prevAccessAllowed === false && accessAllowed === true) {
            // get current emails Queue info
            this.props.getEmailsQueue();
        }
    }

    handlePasswordChange = (ev) => {
        this.setState({
            password: ev.target.value
        });
    };

    handleLoginClick = () => {
        const { password } = this.state;

        if (password) {
            this.props.login(password);
        }
    };

    handleLogoutClick = () => {
        this.props.logout();
    };

    handleActionClick = (ev) => {
        ev.preventDefault();
        switch (ev.target.dataset.action) {
            case 'start':
                this.props.startEmailsQueue();
                break;
            case 'stop':
                this.props.stopEmailsQueue();
                break;
            case 'download':
                this.props.downloadEmailsQueue();
                break;
            case 'restore':
                this.props.restoreEmailsQueue(this.state.file);
                this.setState({ showUploadQueueDataForm: false });
                break;
            case 'show-send-email-form':
                this.setState({ showSendEmailForm: !this.state.showSendEmailForm });
                break;
            case 'show-upload-queue-data-form':
                this.setState({ showUploadQueueDataForm: !this.state.showUploadQueueDataForm });
                break;
            case 'send':
                this.props.sendTestEmail(this.state.email);
                this.setState({ showSendEmailForm: false });
                break;
        }
    };

    handleEmailChange = (ev) => {
        this.setState({ email: ev.target.value });
    };

    handleFileChange = (ev) => {
        this.setState({ file: ev.target.files[0] });
    };

    render() {
        const { isLoading, isEmailsQueueLoading, isActionLoading, accessAllowed, emailsQueue } =
            this.props;
        const { password } = this.state;

        let content = null;
        let header = null;
        let loading = null;

        if (isLoading) {
            loading = <Loading type="overlay" />;
        }

        if (accessAllowed) {
            header = (
                <div className="header">
                    <a href="#" onClick={this.handleLogoutClick}>
                        Logout
                    </a>
                </div>
            );
            content = (
                <div className="emails-queue-wrapper box">
                    <label>Emails Queue</label>
                    {isEmailsQueueLoading ? (
                        <div className="loader-inline-wrapper">
                            <Loading type="inline" />
                        </div>
                    ) : (
                        <div className="content">
                            <div className="info">
                                <label>
                                    Refresh Token Update Date: {emailsQueue.refreshTokenDate}
                                </label>
                                <label>Running: {emailsQueue.running}</label>
                                <label>Paused: {emailsQueue.paused}</label>
                                <label>Length: {emailsQueue.length}</label>
                                <label>Failed Tasks Count: {emailsQueue.failedTasksLength}</label>
                                <label>
                                    Current Task:
                                    {emailsQueue.currentTaskArgs &&
                                    emailsQueue.currentTaskArgs.length > 0
                                        ? ` sending email to ${emailsQueue.currentTaskArgs[2].to}`
                                        : ' - '}
                                </label>
                            </div>
                            <div className="actions">
                                <button
                                    type="button"
                                    data-action="start"
                                    disabled={isActionLoading}
                                    onClick={this.handleActionClick}
                                >
                                    Start
                                </button>
                                <button
                                    type="button"
                                    data-action="stop"
                                    disabled={isActionLoading}
                                    onClick={this.handleActionClick}
                                >
                                    Stop
                                </button>
                                <button
                                    type="button"
                                    data-action="download"
                                    disabled={isActionLoading}
                                    onClick={this.handleActionClick}
                                >
                                    Download
                                </button>
                                <button
                                    type="button"
                                    data-action="show-upload-queue-data-form"
                                    disabled={isActionLoading}
                                    onClick={this.handleActionClick}
                                >
                                    Restore
                                </button>
                                <button
                                    type="button"
                                    data-action="show-send-email-form"
                                    disabled={isActionLoading}
                                    onClick={this.handleActionClick}
                                >
                                    Send test email
                                </button>
                            </div>
                            {this.state.showSendEmailForm && (
                                <form
                                    className="test-email-form"
                                    data-action="send"
                                    onSubmit={this.handleActionClick}
                                >
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        placeholder="email"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                    />
                                    <button
                                        type="submit"
                                        data-action="send"
                                        disabled={isActionLoading}
                                    >
                                        Send
                                    </button>
                                </form>
                            )}
                            {this.state.showUploadQueueDataForm && (
                                <form
                                    className="test-email-form"
                                    data-action="restore"
                                    onSubmit={this.handleActionClick}
                                >
                                    <input
                                        type="file"
                                        name="file"
                                        required
                                        placeholder="file"
                                        // value={this.state.email}
                                        onChange={this.handleFileChange}
                                    />
                                    <button
                                        type="submit"
                                        data-action="restore"
                                        disabled={isActionLoading}
                                    >
                                        Upload
                                    </button>
                                </form>
                            )}
                        </div>
                    )}
                </div>
            );
        } else {
            content = (
                <div className="login-wrapper">
                    <div className="login-form box">
                        <div className="form-group">
                            <label>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={this.handlePasswordChange}
                            />
                        </div>
                        <div className="form-actions">
                            <button
                                type="button"
                                className="primary"
                                disabled={isLoading}
                                onClick={this.handleLoginClick}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="page admin-page">
                {loading}
                {header}
                <div className="page-content-wrapper admin-content">{content}</div>
            </div>
        );
    }
}

const mapStateToProps = ({ admin }) => ({
    isLoading: admin.isLoading,
    isEmailsQueueLoading: admin.isEmailsQueueLoading,
    isActionLoading: admin.isActionLoading,
    accessAllowed: admin.accessAllowed,
    emailsQueue: admin.emailsQueue
});

export default withRouter(
    connect(mapStateToProps, {
        login,
        logout,
        getEmailsQueue,
        startEmailsQueue,
        stopEmailsQueue,
        sendTestEmail,
        downloadEmailsQueue,
        restoreEmailsQueue
    })(PageAdmin)
);
